import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private previousRouteUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private previousECDRouteUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  constructor(private _router: Router) {
  }

  setPreviousRouteUrl(url:string){
    this.previousRouteUrl.next(url);
  }

  getPreviousRouteUrl():Observable<string>{
    return this.previousRouteUrl.asObservable();
  }

  setECDPreviousRouteUrl(url:string){
    this.previousECDRouteUrl.next(url);
  }

  getECDPreviousRouteUrl():Observable<string>{
    return this.previousECDRouteUrl.asObservable();
  }

  extractRouteParams(url:string){
    const params = url.split('?');
    return params;
    
  }

  getUrlParamFromLocation(locationPath:string){
    let urlParams :any = null;
    
    
    const urlString = locationPath;
    let url = decodeURIComponent(urlString);
    urlParams = this.getAllUrlParams(url);
    
    if (Object.keys(urlParams).length === 0) {
      urlParams = null;
    }
    return urlParams;
  }

  getAllUrlParams(url) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      // split our query string into its component parts
      var arr = queryString.split('&');

      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');

        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {

          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string') {
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;
  }


  // public getPreviousUrl() {
  //   return this.previousUrl;
  // }
}
