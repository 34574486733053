import { Panel } from './vehicle';
import { MasterData } from './master-data';
import { DentGrid } from './work-order';
import { Calculation } from './calculation';

const DENTGRID_AVIVA_ID: number = 8;
const DENTGRID_ALLSTATE_ID: number = 900;
const DENTGRID_DCCHAIL_ID: number = 200;
const DENTGRID_MPIC_ID: number = 800;
const DENTGRID_TD_ID: number = 600;
const DENTGRID_SGI_ID: number = 500;
const DENTGRID_COOP_ID: number = 1000;
const DENTGRID_ALLSTATE_CANADA_ID: number = 1100;
const DENTGRID_DGIG_ID: number = 1200;
const DENTGRID_ECONOMICAL_ID: number = 1300;
const DENTGRID_INTACT_ID: number = 1400;
const DENTGRID_SGI_CANADA_ID: number = 1500;
const DENTGRID_WAWANESA_ID: number = 1600;
const DENTGRID_AMERICA_FAMILY_ID: number = 1700;
const DENTGRID_LIBERTY_MUTUAL_ID: number = 1800;
const DENTGRID_FARMERS_INSURANCE_ID: number = 1900;
const DENTGRID_PDRLINX_ID: number = 2000;
const DENTGRID_GEICO_USA_ID: number = 2100;
const DENTGRID_PROGRESSIVE_USA_ID: number = 2200;
const DENTGRID_STATE_FARM_USA_ID: number = 2300;
const DENTGRID_STORM_ART_USA_ID: number = 2400;
const DENTGRID_SOLUTION_WORKS_ID: number = 2500;
const DENTGRID_SGI_MATRIX_ID: number = 2600;

export class Estimate {
  masterData: MasterData;
  calculation: Calculation;
  customerDiscount: number;
  customerTaxRegion: string;
  customerExempt: Object;
  vehicleModelId: number;
  vehicleYear: number;
  vehicleBodyType: string;
  vehicleBodySize: string;
  report: reportUI;
  panelId: number;
  panelIndex: number;
  panels: Array<panelUI>;
  panelBodyId: number;
  panelBodyList: Array<Panel>;
  general: generalUI;
  parts: Array<partIn>;
  selectableParts: Map<number, SelectablePartItem> = new Map();
  moldingList: Array<any>;
  dents: Array<any>;
  dentSizes: Array<any>;
  overSizes: Array<number>;
  flagList: Array<string>;
  dentGrid: DentGrid;
  isDentGrid: boolean;
  selectedDentObj: any;
  selectedDentSizeObj: any;
  removeInstallCatList: Array<removeInstallCat>;
  staticSubJobs: any;
  isDentSizeUi: boolean;
  isOverSizeUi: boolean;
  isDentCategoryUi: boolean;
  isDentCountUi: boolean;
  isLargeVehicle: boolean;
  flagChangeCount: number;
  allowFlagChange: boolean;
  calculationVersion: number;

  constructor() {
    this.masterData = new MasterData();
    this.calculation = new Calculation();
    this.isLargeVehicle = false;
    this.vehicleModelId = null;
    this.vehicleYear = null;
    this.vehicleBodyType = null;
    this.vehicleBodySize = null;
    this.calculationVersion = null;
    this.resetPanelUi();
    this.staticSubJobs = [];
    this.customerTaxRegion = '';
    this.customerExempt = { GST: true, PST: true };
    this.panels = [];
    this.moldingList = [];
    this.panelIndex = 0;
    this.panelBodyList = [];
    this.parts = [];
    this.customerDiscount = 0;
    this.dents = [];
    this.dentSizes = [];
    this.overSizes = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    this.flagList = [
      'ALUMINIUM',
      'DOUBLE PANEL',
      'HSS',
      'LADDER WORK',
      'PRE-EXISTING',
      'CONVENTIONAL REPAIR',
      'MAX PDR',
      'LARGE VEHICLE',
      'P2P',
      'CORROSION PROTECTION',
      'CORROSION',
      'GLUE PULL',
      'BODY LINE',
      'DEEP DENTS',
    ];
    this.report = {
      pdrCharges: [],
      totalPDR: null,
      netSupliment: null,
      partTotal: 0,
      externalParts: [],
      subtotalBeforeDiscount: null,
      discountAmount: null,
      subtotalBeforeTax: null,
      taxRate1: 0,
      taxRate2: 0,
      taxAmount1: 0,
      taxAmount2: 0,
      total: 0,
      partsRequire: [],
      subletCharges: [],
      totalLessDeductible: 0,
      selectedParts: [],
    };
    this.general = {
      removeNis: [],
      customRI: null,
      appraisedRI: null,
      subletCharges: [],
      totalSubletCharge: null,
      flatratePDR: null,
      flatrateRI: null,
      deductible: null,
      rNiArray: [],
      partAmount: 0,
    };
    this.flagChangeCount = 0;
    this.allowFlagChange = false;
    this.loadInitialStaticSubJobs();
    this.loadRemoveInstallCat();
    this.loadSublets();
  }

  loadDents() {
    this.dents = [];
    for (let dent of this.masterData.dentDamageCategories) {
      if (
        !(
          dent.DamageCategoryId == 9 ||
          dent.DamageCategoryId == 10 ||
          dent.DamageCategoryId == 11 ||
          dent.DamageCategoryId == 12 ||
          dent.DamageCategoryId == 13 ||
          dent.DamageCategoryId == 14
        )
      ) {
        this.dents.push(dent);
      } else {
        if (this.dentGrid) {
          if (dent.DamageCategoryId == 9) {
            if (this.dentGrid.DentGridId == DENTGRID_AVIVA_ID) {
              this.dents.push(dent);
            }
          }
          if (dent.DamageCategoryId == 9 || dent.DamageCategoryId == 10) {
            if (
              this.dentGrid.DentGridId == DENTGRID_MPIC_ID ||
              this.dentGrid.DentGridId == DENTGRID_SGI_MATRIX_ID
            ) {
              this.dents.push(dent);
            }
          }

          if (dent.DamageCategoryId == 11) {
            if (
              this.dentGrid.DentGridId == DENTGRID_ALLSTATE_ID ||
              this.dentGrid.DentGridId == DENTGRID_SOLUTION_WORKS_ID ||
              this.dentGrid.DentGridId == DENTGRID_PROGRESSIVE_USA_ID ||
              this.dentGrid.DentGridId == DENTGRID_FARMERS_INSURANCE_ID ||
              this.dentGrid.DentGridId == DENTGRID_GEICO_USA_ID ||
              this.dentGrid.DentGridId == DENTGRID_PDRLINX_ID ||
              this.dentGrid.DentGridId == DENTGRID_LIBERTY_MUTUAL_ID ||
              this.dentGrid.DentGridId == DENTGRID_AMERICA_FAMILY_ID ||
              this.dentGrid.DentGridId == DENTGRID_STORM_ART_USA_ID ||
              this.dentGrid.DentGridId == DENTGRID_STATE_FARM_USA_ID ||
              this.dentGrid.DentGridId == DENTGRID_SOLUTION_WORKS_ID
            ) {
              this.dents.push(dent);
            }
          } else if (dent.DamageCategoryId == 12) {
            if (this.dentGrid.DentGridId == DENTGRID_ALLSTATE_ID) {
              this.dents.push(dent);
            }
          } else if (
            dent.DamageCategoryId == 13 ||
            dent.DamageCategoryId == 14
          ) {
            if (this.dentGrid.DentGridId == DENTGRID_SGI_MATRIX_ID) {
              this.dents.push(dent);
            }
          }
        }
      }
    }
  }
  loadDentSizes() {
    this.dentSizes = [];

    for (let size of this.masterData.dentSizeCategories) {
      //
      if (this.dentGrid) {
        if (this.dentGrid.DentGridId == DENTGRID_TD_ID) {
          if (size.SizeCategoryId != 3 && size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          } else {
            if (this.panels[this.panelIndex]) {
              if (this.panels[this.panelIndex].dentSizeCategoryId == 3)
                this.panels[this.panelIndex].dentSizeCategoryId = null;
            }
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_AVIVA_ID) {
          if (size.SizeCategoryId != 4 && size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          } else {
            if (this.panels[this.panelIndex]) {
              if (this.panels[this.panelIndex].dentSizeCategoryId == 4)
                this.panels[this.panelIndex].dentSizeCategoryId = null;
            }
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_DCCHAIL_ID) {
          if (size.SizeCategoryId != 4 && size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          } else {
            if (this.panels[this.panelIndex]) {
              if (this.panels[this.panelIndex].dentSizeCategoryId == 4)
                this.panels[this.panelIndex].dentSizeCategoryId = null;
            }
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_ALLSTATE_CANADA_ID) {
          if (size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_ALLSTATE_ID) {
          if (size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_COOP_ID) {
          if (size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_DGIG_ID) {
          if (size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_ECONOMICAL_ID) {
          if (size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_INTACT_ID) {
          if (size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_SGI_ID) {
          if (size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_SGI_MATRIX_ID) {
          //   if (size.SizeCategoryId != 5) {
          //     this.dentSizes.push(size);
          //   }
        } else if (this.dentGrid.DentGridId == DENTGRID_SGI_CANADA_ID) {
          if (size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_WAWANESA_ID) {
          if (size.SizeCategoryId != 5) {
            this.dentSizes.push(size);
          }
        } else if (
          this.dentGrid.DentGridId == DENTGRID_SOLUTION_WORKS_ID ||
          this.dentGrid.DentGridId == DENTGRID_PROGRESSIVE_USA_ID ||
          this.dentGrid.DentGridId == DENTGRID_FARMERS_INSURANCE_ID ||
          this.dentGrid.DentGridId == DENTGRID_GEICO_USA_ID ||
          this.dentGrid.DentGridId == DENTGRID_PDRLINX_ID ||
          this.dentGrid.DentGridId == DENTGRID_LIBERTY_MUTUAL_ID ||
          this.dentGrid.DentGridId == DENTGRID_AMERICA_FAMILY_ID ||
          this.dentGrid.DentGridId == DENTGRID_STORM_ART_USA_ID ||
          this.dentGrid.DentGridId == DENTGRID_STATE_FARM_USA_ID
        ) {
          if (size.SizeCategoryId != 3) {
            this.dentSizes.push(size);
          }
        } else if (this.dentGrid.DentGridId == DENTGRID_MPIC_ID) {
        } else {
          this.dentSizes.push(size);
        }
      } else {
        this.dentSizes.push(size);
      }
    }
  }
  selectAppraisedRI(cat) {
    //
    let isExists = false;
    let i = this.general.removeNis.indexOf(cat.RemoveInstallCategoryId);

    if (i > -1) {
      isExists = true;
      this.general.removeNis.splice(i, 1);
      this.general.rNiArray.splice(i, 1);
    }
    if (isExists == false) {
      this.general.removeNis.push(cat.RemoveInstallCategoryId);
      this.general.rNiArray.push(this.getRnIObj(cat.RemoveInstallCategoryId));
    }
    this.general.rNiArray = [];
    for (let rNis of this.general.removeNis) {
      let rNisFull = this.getRnIObj(rNis);

      if (rNisFull) {
        let obj = { name: rNisFull.name, coverage: rNisFull.coverage };
        this.general.rNiArray.push(obj);
      }
    }
    //
    this.onChangeCalc();
  }
  loadInitialStaticSubJobs() {
    this.staticSubJobs = [];
    for (let subjob of this.masterData.subJobs) {
      if (subjob.isPermanent) {
        this.staticSubJobs.push(subjob);
      }
    }
  }

  checkRnIsFlag(cat) {
    if (this.general.removeNis.indexOf(cat) > -1) {
      return true;
    } else {
      return false;
    }
  }

  // addParts(partObj : partIn) {
  //     this.panels[this.panelIndex].parts.push({name: partObj.name,price:partObj.price});
  // }

  // addGeneralParts(partObj : partIn){
  //     this.parts.push({name: partObj.name,price:partObj.price});
  // }
  // removeParts(index){
  //     // let index = this.panels.indexOf();
  //     this.panels[this.panelIndex].parts.splice(index,1);
  // }
  // removeeneralParts(index){
  //     this.parts.splice(index,1);
  // }

  loadRemoveInstallCat() {
    if (this.general) {
      //this.general.removeNis = [];
    }
    if (this.dentGrid) {
      this.removeInstallCatList = [];
      for (let item of this.dentGrid.RemoveInstallCoverageItems) {
        //
        let cat = this.masterData.removeInstallCategories.find(
          (i) => i.RemoveInstallCategoryId === item.RemoveInstallCategoryId
        );

        if (cat) this.removeInstallCatList.push(cat);
      }
    } else {
      this.removeInstallCatList = this.masterData.removeInstallCategories;
    }

    this.onChangeCalc();
  }
  loadSublets() {
    this.general.subletCharges = [
      {
        name: 'Conventional Repair',
        amount: null,
      },
      // {
      //   name: 'Rental Car',
      //   amount: null,
      // },
      {
        name: 'Parts',
        amount: null,
      },
      {
        name: 'Labor',
        amount: null,
      },
      {
        name: 'Miscellaneous',
        amount: null,
      },
      {
        name: 'Calibration',
        amount: null,
      },
      {
        name: 'Glass',
        amount: null,
      },
      {
        name: 'PPF',
        amount: null,
      },
      {
        name: 'Towing',
        amount: null,
      },
    ];
  }

  getFilteredSubletCharges() {
    return this.general.subletCharges.filter((sublet) => sublet.amount > 0);
  }

  putPanelBodyList(panelList) {
    this.panelBodyList = panelList;
  }

  calculateRnI() {
    let rNiAmount = 0;
    let totalSubletCharge = 0;

    for (let id of this.general.removeNis) {
      let coverage = 0;
      let rAndIItem = this.dentGrid.RemoveInstallCoverageItems.find(
        (i) => i.RemoveInstallCategoryId === id
      );
      //
      //
      //
      let bodyTypeItem = this.masterData.bodyTypes.find(
        (i) => i.BodyTypeId === this.vehicleBodyType
      );

      //
      if (rAndIItem) {
        if (bodyTypeItem && rAndIItem.CatCoverage) {
          let sizeType = bodyTypeItem.sizeType;
          if (sizeType) {
            let catObj = rAndIItem.CatCoverage[sizeType];
            //
            if (catObj) {
              //
              let dynamicCoverage = catObj[this.vehicleBodySize];
              if (dynamicCoverage) {
                coverage = dynamicCoverage;
              } else {
                coverage = rAndIItem.Coverage;
              }
            } else {
              coverage = rAndIItem.Coverage;
              //
            }
          } else {
            coverage = rAndIItem.Coverage;
          }
        } else {
          coverage = rAndIItem.Coverage;
        }
      }

      rNiAmount = rNiAmount + coverage;
    }
    this.general.appraisedRI = this.general.customRI + rNiAmount;
    for (let sublet of this.general.subletCharges) {
      let coverage = sublet.amount;

      totalSubletCharge = totalSubletCharge + coverage;
    }
    this.general.totalSubletCharge = totalSubletCharge;
  }

  getRnIObj(id) {
    let rniobj = {
      name: '',
      coverage: 0,
    };
    let itemMasterData = this.masterData.removeInstallCategories.find(
      (i) => i.RemoveInstallCategoryId === id
    );
    if (itemMasterData) rniobj.name = itemMasterData.Name;
    let itemDentgrid = this.dentGrid.RemoveInstallCoverageItems.find(
      (i) => i.RemoveInstallCategoryId === id
    );

    let bodyTypeItem = this.masterData.bodyTypes.find(
      (i) => i.BodyTypeId === this.vehicleBodyType
    );

    if (itemDentgrid) {
      if (bodyTypeItem && itemDentgrid.CatCoverage) {
        let sizeType = bodyTypeItem.sizeType;
        if (sizeType) {
          let catObj = itemDentgrid.CatCoverage[sizeType];

          if (catObj) {
            let dynamicCoverage = catObj[this.vehicleBodySize];
            if (dynamicCoverage) {
              rniobj.coverage = dynamicCoverage;
            } else {
              rniobj.coverage = itemDentgrid.Coverage;
            }
          } else {
            rniobj.coverage = itemDentgrid.Coverage;
          }
        } else {
          rniobj.coverage = itemDentgrid.Coverage;
        }
      } else {
        rniobj.coverage = itemDentgrid.Coverage;
      }
    }

    return rniobj;
  }

  loadPanel(panelObj: Panel, panelList) {
    this.panelBodyList = panelList;

    let panel: panelUI = {
      panelId: null,
      appraisal: [''],
      appraisalDetails: [],
      damageCategory: null,
      dentSizeCategoryId: null,
      oversize: null,
      flags: [],
      moldings: [],
      panelName: null,
      partPrize: null,
      appraisedNoLarge: null,
      appraised: null,
      flatRate: null,
      dentCount: null,
      parts: [],
    };

    let isFirst = true;
    for (let i = 0; i < this.panels.length; i++) {
      if (this.panels[i].panelId == panelObj.PanelId) {
        isFirst = false;
        this.panelIndex = i;
        this.panelId = this.panels[i].panelId;

        // if (this.allowFlagChange == true) {
        //     this.setFlags(this.panels[i]);
        //
        //
        //     this.calcAllPanels();
        // }
      }
    }
    if (isFirst == true) {
      panel.panelId = panelObj.PanelId;
      this.panelId = panelObj.PanelId;
      this.panelIndex = this.panels.length;
      panel.panelName = panelObj.PanelName;
      this.panels.push(panel);
      if (this.allowFlagChange == true) {
        this.setFlags(panel);
        //
        //
        this.calcAllPanels();
      }
    }

    this.moldingList = [];
    for (let j = 0; j < this.masterData.moldings.length; j++) {
      if (this.masterData.moldings[j].PanelId == this.panelId) {
        this.moldingList.push(this.masterData.moldings[j]);
      }
    }
    this.updateFlags();
  }

  selectDent(dent) {
    //
    if (this.panels[this.panelIndex].damageCategory == dent.DamageCategoryId) {
      this.panels[this.panelIndex].damageCategory = null;
      this.selectedDentObj = null;
    } else {
      this.panels[this.panelIndex].damageCategory = dent.DamageCategoryId;
      this.selectedDentObj = dent;
      if (!this.panels[this.panelIndex].dentSizeCategoryId) {
        if (this.dentSizes[0])
          this.panels[this.panelIndex].dentSizeCategoryId =
            this.dentSizes[0].SizeCategoryId;
      }
    }
    if (this.dentGrid) {
      this.onChangeCalc();
    }
  }

  onChangeDentCount() {
    this.onChangeCalc();
  }
  selectSize(size) {
    if (
      this.panels[this.panelIndex].dentSizeCategoryId == size.SizeCategoryId
    ) {
      this.panels[this.panelIndex].dentSizeCategoryId = null;
      this.selectedDentSizeObj = null;
    } else {
      this.panels[this.panelIndex].dentSizeCategoryId = size.SizeCategoryId;
      this.selectedDentSizeObj = size;
    }
    if (this.dentGrid) {
      this.onChangeCalc();
    }
  }
  selectOverSize(size) {
    if (this.panels[this.panelIndex].oversize == size) {
      this.panels[this.panelIndex].oversize = null;
    } else {
      this.panels[this.panelIndex].oversize = size;
    }
    if (this.dentGrid) {
      this.onChangeCalc();
    }
  }

  changeOverSize() {
    if (this.dentGrid) {
      this.onChangeCalc();
    }
  }

  changeVehicleModelYear(modelId, year) {
    //
    this.vehicleModelId = modelId;
    this.vehicleYear = year;
    if (this.allowFlagChange == true) {
      for (let panel of this.panels) {
        panel.flags = [];
        this.setFlags(panel);
      }
      //
      //
      this.calcAllPanels();
    }
  }

  changeVehicleBodyTypeAndSize(bodyTypeId, bodySizeId) {
    //
    this.vehicleBodyType = bodyTypeId;
    this.vehicleBodySize = bodySizeId;

    this.onChangeCalc();
  }

  checkFlag(flag) {
    if (this.panels[this.panelIndex].flags.indexOf(flag) > -1) {
      return true;
    } else {
      return false;
    }
  }

  setFlags(panel) {
    let flagObj = this.masterData.flags.find(
      (i) =>
        i.modelId == this.vehicleModelId &&
        i.year == this.vehicleYear &&
        i.panelId == panel.panelId
    );

    if (flagObj) {
      for (let flag of flagObj.flags) {
        let flagCore = this.masterData.panelFlags.find((i) => i.id == flag);
        if (flagCore) {
          let flagStr = this.flagList.find((i) => i == flagCore.name);
          if (flagStr) {
            let flagIndex = panel.flags.findIndex((i) => i == flagStr);
            if (flagIndex < 0) {
              panel.flags.push(flagCore.name);
            }
          } else {
            let flagIndex = panel.flags.findIndex((i) => i == flagStr);
            if (flagIndex > -1) {
              panel.flags.splice(flagIndex, 1);
            }
          }
        }
      }
    }
  }

  calcAllPanels() {
    for (let panel of this.panels) {
      this.generateAppraiseStr(panel);
      this.calculatePanelPDR(panel);
      this.changePannelState(panel);
    }

    //  this.allowFlagChange = true;
    //
  }
  calcAllPanelsWithFlag() {
    for (let panel of this.panels) {
      this.generateAppraiseStr(panel);
      this.calculatePanelPDR(panel);
      this.changePannelState(panel);
    }

    //  this.allowFlagChange = true;
    //
  }

  selectFlag(flag) {
    let promise = new Promise((resolve, reject) => {
      let isExists = false;
      let i = this.panels[this.panelIndex].flags.indexOf(flag);
      if (i > -1) {
        isExists = true;
        this.panels[this.panelIndex].flags.splice(i, 1);
        if (flag == 'LARGE VEHICLE') {
          this.isLargeVehicle = false;
        }
      }
      if (isExists == false) {
        this.panels[this.panelIndex].flags.push(flag);
        if (flag == 'LARGE VEHICLE') {
          this.isLargeVehicle = true;
        }
      }

      if (this.dentGrid) {
        this.onChangeCalc();
      }

      //resolve();
    });
    return promise;
  }

  enterFlatRate() {
    this.onChangeCalc();
  }

  checkMolding(molding) {
    for (let i = 0; i < this.panels[this.panelIndex].moldings.length; i++) {
      if (this.panels[this.panelIndex].moldings[i] == molding) {
        return true;
      }
    }
    return false;
  }

  selectMolding(molding) {
    let isExists = false;
    for (let i = 0; i < this.panels[this.panelIndex].moldings.length; i++) {
      if (this.panels[this.panelIndex].moldings[i] == molding) {
        isExists = true;
        if (i > -1) {
          this.panels[this.panelIndex].moldings.splice(i, 1);
        }
      }
    }
    if (isExists == false) this.panels[this.panelIndex].moldings.push(molding);

    if (this.dentGrid) {
      this.onChangeCalc();
    }
  }

  addSuppliment() {
    let aLength = this.panels[this.panelIndex].appraisal.length;
    if (this.panels[this.panelIndex].appraisal[aLength - 1]) {
      this.panels[this.panelIndex].appraisal.push(
        this.panels[this.panelIndex].appraisal[aLength - 1]
      );
    }
  }

  updateFlags() {
    if (this.dentGrid) {
      this.flagList = [];
      if (this.panels[this.panelIndex]) {
        // this.panels[this.panelIndex].flags = [];
      }
      let dentGridRules = this.dentGrid.DentGridRules;

      this.flagList.push('ALUMINIUM');
      if (dentGridRules.DoublePanelUpchargePercent) {
        this.flagList.push('DOUBLE PANEL');
      }

      if (dentGridRules.HighStrengthSteelUpchargePercent != undefined) {
        this.flagList.push('HSS');
      }
      if (dentGridRules.LargeVehicleUpchargePercent) {
        if (this.panels[this.panelIndex]) {
          if (this.panels[this.panelIndex].panelId == 2) {
            this.flagList.push('LARGE VEHICLE');
          }
        }
      }
      if (
        this.panelId == 2 &&
        this.dentGrid.DentGridId != DENTGRID_SGI_ID &&
        this.dentGrid.DentGridId != DENTGRID_SGI_MATRIX_ID &&
        this.dentGrid.DentGridId != DENTGRID_MPIC_ID
      ) {
        this.flagList.push('LADDER WORK');
      }
      this.flagList.push('PRE-EXISTING');
      this.flagList.push('CONVENTIONAL REPAIR');
      this.flagList.push('MAX PDR');
      this.flagList.push('P2P');
      if (
        this.dentGrid.DentGridId == DENTGRID_COOP_ID ||
        this.dentGrid.DentGridId == DENTGRID_DGIG_ID ||
        this.dentGrid.DentGridId == DENTGRID_GEICO_USA_ID ||
        this.dentGrid.DentGridId == DENTGRID_PROGRESSIVE_USA_ID ||
        this.dentGrid.DentGridId == DENTGRID_STATE_FARM_USA_ID
      ) {
        this.flagList.push('CORROSION PROTECTION');
      }
      if (this.dentGrid.DentGridId == DENTGRID_AMERICA_FAMILY_ID) {
        this.flagList.push('CORROSION');
        this.flagList.push('GLUE PULL');
      }
      if (this.dentGrid.DentGridId == DENTGRID_PROGRESSIVE_USA_ID) {
        this.flagList.push('BODY LINE');
        this.flagList.push('DEEP DENTS');
      }
    }
  }

  /**
   * Calculate overall prices for each scenario
   */
  onChangeCalc() {
    this.calculateRnI();
    this.generateAppraiseStr(this.panels[this.panelIndex]);
    this.calculatePanelPDR(this.panels[this.panelIndex]);
    this.changePannelState(this.panels[this.panelIndex]);
    this.onLargeVehicle();
  }

  /**
   * Generate Appraisal(s)
   * @param panel
   */
  generateAppraiseStr(panel) {
    if (panel) {
      let aLength = panel.appraisal.length;
      panel.appraisalDetails = [];
      let appraiseStr = 'N/D';
      if (this.dents.find((i) => i.DamageCategoryId === panel.damageCategory)) {
        if (appraiseStr == 'N/D') {
          appraiseStr = '';
        }
        let dentString = this.dents.find(
          (i) => i.DamageCategoryId === panel.damageCategory
        ).Description;
        appraiseStr = appraiseStr + dentString;
        if (appraiseStr != '') {
          let detailObj = {
            Dents: dentString,
          };
          panel.appraisalDetails.push(detailObj);
        }
      } else if (panel.dentCount > 0) {
        if (appraiseStr == 'N/D') {
          appraiseStr = '';
        }
        let dentCountString = panel.dentCount;
        appraiseStr = appraiseStr + dentCountString;
        if (appraiseStr != '') {
          let detailObj = {
            Dents: dentCountString,
          };
          panel.appraisalDetails.push(detailObj);
        }
      }
      if (
        this.dentSizes.find(
          (i) => i.SizeCategoryId === panel.dentSizeCategoryId
        )
      ) {
        if (appraiseStr == 'N/D') {
          appraiseStr = '';
        }
        let sizeCategoryId =
          ' ' +
          this.dentSizes
            .find((i) => i.SizeCategoryId === panel.dentSizeCategoryId)
            .Name.charAt(0);
        appraiseStr = appraiseStr + sizeCategoryId;
        if (appraiseStr != '') {
          let detailObj = {
            'Size category': sizeCategoryId,
          };
          panel.appraisalDetails.push(detailObj);
        }
      }
      if (panel.oversize) {
        if (appraiseStr == 'N/D') {
          appraiseStr = '';
        }
        let oversizeString = ' +' + panel.oversize;
        appraiseStr = appraiseStr + oversizeString;
        if (appraiseStr != '') {
          let detailObj = {
            Oversize: oversizeString,
          };
          panel.appraisalDetails.push(detailObj);
        }
      }
      if (panel.flags.length > 0) {
        let detailObj = {
          Flags: [],
        };
        for (let i = 0; i < panel.flags.length; i++) {
          if (appraiseStr == 'N/D') {
            appraiseStr = '';
          }
          let flags = panel.flags;

          if (flags[i] == 'ALUMINIUM') {
            appraiseStr = appraiseStr + ' AL';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'DOUBLE PANEL') {
            appraiseStr = appraiseStr + ' DP';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'HSS') {
            appraiseStr = appraiseStr + ' HSS';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'LADDER WORK') {
            appraiseStr = appraiseStr + ' LDR';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'PRE-EXISTING') {
            appraiseStr = appraiseStr + ' PD';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'CONVENTIONAL REPAIR') {
            appraiseStr = appraiseStr + ' CR';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'MAX PDR') {
            appraiseStr = appraiseStr + ' MP';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'LARGE VEHICLE') {
            appraiseStr = appraiseStr + ' LV';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'P2P') {
            appraiseStr = appraiseStr + ' P2P';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'CORROSION PROTECTION') {
            appraiseStr = appraiseStr + ' CP';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'CORROSION') {
            appraiseStr = appraiseStr + ' C';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'GLUE PULL') {
            appraiseStr = appraiseStr + ' GP';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'BODY LINE') {
            appraiseStr = appraiseStr + ' BL';
            detailObj.Flags.push(flags[i]);
          }
          if (flags[i] == 'DEEP DENTS') {
            appraiseStr = appraiseStr + ' DP';
            detailObj.Flags.push(flags[i]);
          }
        }
        panel.appraisalDetails.push(detailObj);
      }
      if (panel.flatRate) {
        if (appraiseStr == 'N/D') {
          appraiseStr = '';
        }
        appraiseStr = appraiseStr + ' $' + panel.flatRate;
        if (appraiseStr != '') {
          let detailObj = {
            Oversize: appraiseStr,
          };
          panel.appraisalDetails.push(detailObj);
        }
      }

      panel.appraisal[aLength - 1] = appraiseStr;
    }
  }

  changePannelState(panel) {
    let state = 'N_D';
    if (panel) {
      if (panel.flatRate) {
        state = 'FLAT_RATE';
      } else if (panel.flags.indexOf('MAX PDR') > -1) {
        state = 'MAX_PDR';
      } else if (panel.flags.indexOf('CONVENTIONAL REPAIR') > -1) {
        state = 'CONV_REPAIRE';
      } else if (panel.appraised > 0) {
        state = 'APPRAISED';
        if (!(panel.damageCategory || panel.dentCount)) {
          state = 'ASK_INCOMPLETE';
        }
        if (this.selectedDentObj.DentGridId != DENTGRID_SGI_ID) {
          if (
            this.selectedDentObj.DentGridId != DENTGRID_MPIC_ID &&
            this.selectedDentObj.DentGridId != DENTGRID_SGI_MATRIX_ID &&
            !panel.dentSizeCategoryId
          ) {
            state = 'ASK_INCOMPLETE';
          }
        } else if (!panel.dentCount) {
          state = 'ASK_INCOMPLETE';
        }

        if (panel.result) {
          if (panel.result.DentCoverage == 0) {
            state = 'ASK_INCOMPLETE';
          }
        }
      } else if (
        (this.dents.find((i) => i.DamageCategoryId === panel.damageCategory) ||
          panel.dentCount > 0) &&
        this.dentSizes.find(
          (i) => i.SizeCategoryId === panel.dentSizeCategoryId
        )
      ) {
        state = 'APPRAISED';
        if (panel.appraised == 0 || panel.appraised == null) {
          state = 'ASK_INCOMPLETE';
          if (panel.flatRate && panel.flatRate > 0) {
            state = 'APPRAISED';
          }
        }
      } else if (
        (this.dents.find((i) => i.DamageCategoryId === panel.damageCategory) ||
          panel.dentCount > 0) &&
        !this.dentSizes.find(
          (i) => i.SizeCategoryId === panel.dentSizeCategoryId
        )
      ) {
        state = 'ASK_INCOMPLETE';
      } else if (
        !(
          this.dents.find((i) => i.DamageCategoryId === panel.damageCategory) ||
          panel.dentCount > 0
        ) &&
        this.dentSizes.find(
          (i) => i.SizeCategoryId === panel.dentSizeCategoryId
        )
      ) {
        state = 'ASK_INCOMPLETE';
      } else if (panel.oversize) {
        state = 'APPRAISED';
        if (panel.appraised == 0 || panel.appraised == null) {
          state = 'ASK_INCOMPLETE';
          if (panel.flatRate && panel.flatRate > 0) {
            state = 'APPRAISED';
          }
        }
      } else {
        state = 'N_D';
      }

      if (panel.appraised > this.masterData.maxPanelValue) {
        state = 'ASK_INCOMPLETE';
      }
      // APPRAISED
      // ASK_INCOMPLETE
      // CONV_REPAIRE
      // FLAT_RATE
      // MAX_PDR
      if (this.panelBodyList) {
        let pBodyIndex = this.panelBodyList.findIndex(
          (i) => i.PanelId === panel.panelId
        );

        if (pBodyIndex > -1) {
          let aLength = panel.appraisal.length;
          this.panelBodyList[pBodyIndex].state = state;
          this.panelBodyList[pBodyIndex].coding = panel.appraisal[aLength - 1];
        }
      }
    }
  }

  onChangeDentGrid(dentGrid) {
    //
    if (dentGrid == undefined) {
      this.general.removeNis = [];
    } else if (this.dentGrid != undefined && this.dentGrid != dentGrid) {
      this.general.removeNis = [];
    }
    this.isDentGrid = true;
    let previousDentGrid = this.dentGrid;
    this.dentGrid = dentGrid;

    this.loadDentSizes();
    this.loadDents();

    this.loadRemoveInstallCat();
    this.updateFlags();
    this.onChangeCalc();

    if (this.dentGrid) {
      if (this.dentGrid.DentGridId == DENTGRID_SGI_ID) {
        this.resetPanelUi();
        this.isDentSizeUi = false;
        this.isDentCategoryUi = false;
        this.isDentCountUi = true;
      } else if (this.dentGrid.DentGridId == DENTGRID_SGI_MATRIX_ID) {
        this.resetPanelUi();
        this.isDentSizeUi = false;
      } else if (this.dentGrid.DentGridId == DENTGRID_MPIC_ID) {
        this.resetPanelUi();
        this.isDentSizeUi = false;
      } else if (this.dentGrid.DentGridId == DENTGRID_WAWANESA_ID) {
        this.resetPanelUi();
        this.isOverSizeUi = false;
      } else {
        this.resetPanelUi();
      }
    } else {
      this.isDentCategoryUi = true;
    }

    if (this.allowFlagChange == true) {
      for (let panel of this.panels) {
        panel.flags = [];
        if (this.dentGrid.DentGridId == DENTGRID_SGI_ID) {
          panel.damageCategory = null;
          panel.dentSizeCategoryId = null;
        } else {
          panel.dentCount = null;
        }
        //panel.dentSizeCategoryId = null;
        this.setFlags(panel);
        this.generateAppraiseStr(panel);
        this.calculatePanelPDR(panel);
        this.changePannelState(panel);
      }

      //
      //
    }
  }
  resetPanelUi() {
    this.isDentSizeUi = true;
    this.isOverSizeUi = true;
    this.isDentCategoryUi = true;
    this.isDentCountUi = false;
  }

  onLargeVehicle() {
    //
    let roofPanel = this.panels.filter((x) => x.panelId == 2)[0];

    if (roofPanel) {
      let isLargeVehicleIndex = roofPanel.flags.indexOf('LARGE VEHICLE');
      if (isLargeVehicleIndex > -1) {
        for (let panel of this.panels) {
          let isLargeVehiclePanelIndex = panel.flags.indexOf('LARGE VEHICLE');

          if (isLargeVehiclePanelIndex <= -1) {
            panel.flags.push('LARGE VEHICLE');
          }
          //panel.dentSizeCategoryId = null;
          this.setFlags(panel);
          this.generateAppraiseStr(panel);
          this.calculatePanelPDR(panel);
          this.changePannelState(panel);
        }
      } else {
        for (let panel of this.panels) {
          let isLargeVehiclePanelIndex = panel.flags.indexOf('LARGE VEHICLE');
          //
          if (isLargeVehiclePanelIndex > -1) {
            panel.flags.splice(isLargeVehiclePanelIndex, 1);
          }

          //panel.dentSizeCategoryId = null;
          this.setFlags(panel);
          this.generateAppraiseStr(panel);
          this.calculatePanelPDR(panel);
          this.changePannelState(panel);
        }
      }
    }
  }

  /**
   *
   * @param panel Calculate panel related PDR rates
   */
  calculatePanelPDR(panel) {
    if (sessionStorage.getItem('dentGrid') != 'undefined' && panel) {
      let dentGridSelected: DentGrid = JSON.parse(
        sessionStorage.getItem('dentGrid')
      );
      this.selectedDentObj = dentGridSelected;

      let selectedPanel: panelUI = panel;

      let calculateModel = dentGridSelected.DentGridRules.DentGridModel;
      const isAluminumIndex = selectedPanel.flags.indexOf('ALUMINIUM');
      const isDoublePanelIndex = selectedPanel.flags.indexOf('DOUBLE PANEL');
      const isHSSIndex = selectedPanel.flags.indexOf('HSS');
      const isLadderWorkIndex = selectedPanel.flags.indexOf('LADDER WORK');
      const isLargeVehicleIndex = selectedPanel.flags.indexOf('LARGE VEHICLE');
      const isConventionalRepairIndex = selectedPanel.flags.indexOf(
        'CONVENTIONAL REPAIR'
      );
      const isMaxPDRIndex = selectedPanel.flags.indexOf('MAX PDR');
      const isCorrosionProtectIndex = selectedPanel.flags.indexOf(
        'CORROSION PROTECTION'
      );
      const isCorrosionIndex = selectedPanel.flags.indexOf('CORROSION');
      const isBodyLineIndex = selectedPanel.flags.indexOf('BODY LINE');
      const isDeepDentsIndex = selectedPanel.flags.indexOf('DEEP DENTS');
      const isGluePullIndex = selectedPanel.flags.indexOf('GLUE PULL');

      let isAluminum = false;
      if (isAluminumIndex > -1) isAluminum = true;

      let isDoublePanel = false;
      if (isDoublePanelIndex > -1) isDoublePanel = true;

      let isHSS = false;
      if (isHSSIndex > -1) isHSS = true;

      let isLadderWork = false;
      if (isLadderWorkIndex > -1) isLadderWork = true;

      let isLargeVehicle = false;
      if (isLargeVehicleIndex > -1) isLargeVehicle = true;

      let isConventionalRepair = false;
      if (isConventionalRepairIndex > -1) isConventionalRepair = true;

      let isMaxPDR = false;
      if (isMaxPDRIndex > -1) isMaxPDR = true;

      let isCorrosionProtect = false;
      if (isCorrosionProtectIndex > -1) isCorrosionProtect = true;

      let isCorrosion = false;
      if (isCorrosionIndex > -1) isCorrosion = true;

      let isGluePull = false;
      if (isGluePullIndex > -1) isGluePull = true;

      let isBodyLine = false;
      if (isBodyLineIndex > -1) isBodyLine = true;

      let isDeepDents = false;
      if (isDeepDentsIndex > -1) isDeepDents = true;

      if (this.calculationVersion == 1) {
        //
        let result = null;
        //Get old version dent grid
        let dentGridTemp = this.getOldVesionDentGrid(
          dentGridSelected.DentGridId,
          1
        );
        if (dentGridTemp) {
          dentGridSelected = dentGridTemp;
        }
        switch (calculateModel) {
          case 'dcchail-1':
            result = this.calculation.calculateDentCoverageDccHailOld(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLadderWork,
              isLargeVehicle
            );
            break;
          case 'allstate-1':
            result = this.calculation.calculateDentCoverageAllstateOld(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLadderWork,
              isLargeVehicle
            );
            break;
          case 'sgi-1':
            result = this.calculation.calculateDentCoverageSgiOld(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.dentCount,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel
            );
            break;
          case 'mpic-1':
            result = this.calculation.calculateDentCoverageMpicOld(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel
            );
            break;
          case 'td-1':
            result = this.calculation.calculateDentCoverageTdOld(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork
            );
            break;
          case 'coOperators-1':
            result = this.calculation.calculateDentCoverageCoOperators(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'allstate-canada-1':
            result = this.calculation.calculateDentCoverageAllstateCanada(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'DGIG-1':
            result = this.calculation.calculateDentCoverageDGIG(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'ECONOMICAL-1':
            result = this.calculation.calculateDentCoverageEconomical(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'intact-1':
            result = this.calculation.calculateDentCoverageIntact(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'sgi-canada-1':
            result = this.calculation.calculateDentCoverageSgiCanada(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'wawanesa-1':
            result = this.calculation.calculateDentCoverageWawanesa(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'american_family-1':
            result = this.calculation.calculateDentCoverageAmericanFamily(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosion,
              isGluePull
            );
            break;
          case 'liberty_mutual-1':
            result = this.calculation.calculateDentCoverageLibertyMutual(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'farmers_insurance-1':
            result = this.calculation.calculateDentCoverageFarmersInsu(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'pdrlinx-1':
            result = this.calculation.calculateDentCoveragePDRLinx(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'geico-1':
            result = this.calculation.calculateDentCoverageGeicoUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'progressive-1':
            result = this.calculation.calculateDentCoverageProgressiveUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect,
              isBodyLine,
              isDeepDents
            );
            break;
          case 'state_farm-1':
            result = this.calculation.calculateDentCoverageStateFarmUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'storm_art-1':
            result = this.calculation.calculateDentCoverageStormArtUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'solution_works-1':
            result = this.calculation.calculateDentCoverageSolutionWorks(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'sgi-matrix':
            result = this.calculation.calculateDentCoverageSgiMatrix(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isConventionalRepair,
              isMaxPDR
            );
            break;
        }
        if (result) {
          selectedPanel.appraised =
            +(result.DentCoverage || 0) +
            (result.OversizeCoverage || 0) +
            (result.Upcharge || 0);
          selectedPanel.result = result;
          //
        }
      } else if (this.calculationVersion == 2) {
        //

        let result = null;
        //Get old version dent grid
        let dentGridTemp = this.getOldVesionDentGrid(
          dentGridSelected.DentGridId,
          1
        );
        //
        if (dentGridTemp) {
          dentGridSelected = dentGridTemp;
        }
        switch (calculateModel) {
          case 'dcchail-1':
            result = this.calculation.calculateDentCoverageDccHail(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLadderWork,
              isLargeVehicle,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'allstate-1':
            result = this.calculation.calculateDentCoverageAllstate(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLadderWork,
              isLargeVehicle,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'sgi-1':
            result = this.calculation.calculateDentCoverageSgi(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.dentCount,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'mpic-1':
            result = this.calculation.calculateDentCoverageMpic(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'td-1':
            result = this.calculation.calculateDentCoverageTd(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'coOperators-1':
            result = this.calculation.calculateDentCoverageCoOperators(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'allstate-canada-1':
            result = this.calculation.calculateDentCoverageAllstateCanada(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'DGIG-1':
            result = this.calculation.calculateDentCoverageDGIG(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'ECONOMICAL-1':
            result = this.calculation.calculateDentCoverageEconomical(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'intact-1':
            result = this.calculation.calculateDentCoverageIntact(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'sgi-canada-1':
            result = this.calculation.calculateDentCoverageSgiCanada(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'wawanesa-1':
            result = this.calculation.calculateDentCoverageWawanesa(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'american_family-1':
            result = this.calculation.calculateDentCoverageAmericanFamily(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosion,
              isGluePull
            );
            break;
          case 'liberty_mutual-1':
            result = this.calculation.calculateDentCoverageLibertyMutual(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'farmers_insurance-1':
            result = this.calculation.calculateDentCoverageFarmersInsu(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'pdrlinx-1':
            result = this.calculation.calculateDentCoveragePDRLinx(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'geico-1':
            result = this.calculation.calculateDentCoverageGeicoUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'progressive-1':
            result = this.calculation.calculateDentCoverageProgressiveUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect,
              isBodyLine,
              isDeepDents
            );
            break;
          case 'state_farm-1':
            result = this.calculation.calculateDentCoverageStateFarmUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'storm_art-1':
            result = this.calculation.calculateDentCoverageStormArtUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'solution_works-1':
            result = this.calculation.calculateDentCoverageSolutionWorks(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'sgi-matrix':
            result = this.calculation.calculateDentCoverageSgiMatrix(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isConventionalRepair,
              isMaxPDR
            );
            break;
        }
        if (result) {
          let appraised =
            +(result.DentCoverage || 0) +
            (result.OversizeCoverage || 0) +
            (result.Upcharge || 0);

          selectedPanel.appraised = Math.round(appraised * 100) / 100;
          selectedPanel.result = result;
        }
      } else {
        //
        //
        let result = null;
        switch (calculateModel) {
          case 'dcchail-1':
            result = this.calculation.calculateDentCoverageDccHail(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLadderWork,
              isLargeVehicle,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'allstate-1':
            result = this.calculation.calculateDentCoverageAllstate(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLadderWork,
              isLargeVehicle,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'sgi-1':
            result = this.calculation.calculateDentCoverageSgi(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.dentCount,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'mpic-1':
            result = this.calculation.calculateDentCoverageMpic(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'td-1':
            result = this.calculation.calculateDentCoverageTd(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'coOperators-1':
            result = this.calculation.calculateDentCoverageCoOperators(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'allstate-canada-1':
            result = this.calculation.calculateDentCoverageAllstateCanada(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'DGIG-1':
            result = this.calculation.calculateDentCoverageDGIG(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'ECONOMICAL-1':
            result = this.calculation.calculateDentCoverageEconomical(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'intact-1':
            result = this.calculation.calculateDentCoverageIntact(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'sgi-canada-1':
            result = this.calculation.calculateDentCoverageSgiCanada(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'wawanesa-1':
            result = this.calculation.calculateDentCoverageWawanesa(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'american_family-1':
            result = this.calculation.calculateDentCoverageAmericanFamily(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosion,
              isGluePull
            );
            break;
          case 'liberty_mutual-1':
            result = this.calculation.calculateDentCoverageLibertyMutual(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'farmers_insurance-1':
            result = this.calculation.calculateDentCoverageFarmersInsu(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'pdrlinx-1':
            result = this.calculation.calculateDentCoveragePDRLinx(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR
            );
            break;
          case 'geico-1':
            result = this.calculation.calculateDentCoverageGeicoUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'progressive-1':
            result = this.calculation.calculateDentCoverageProgressiveUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect,
              isBodyLine,
              isDeepDents
            );
            break;
          case 'state_farm-1':
            result = this.calculation.calculateDentCoverageStateFarmUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'storm_art-1':
            result = this.calculation.calculateDentCoverageStormArtUSA(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'solution_works-1':
            result = this.calculation.calculateDentCoverageSolutionWorks(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.dentSizeCategoryId,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isHSS,
              isLargeVehicle,
              isLadderWork,
              isConventionalRepair,
              isMaxPDR,
              isCorrosionProtect
            );
            break;
          case 'sgi-matrix':
            result = this.calculation.calculateDentCoverageSgiMatrix(
              dentGridSelected,
              selectedPanel.panelId,
              selectedPanel.damageCategory,
              selectedPanel.oversize,
              isAluminum,
              isDoublePanel,
              isConventionalRepair,
              isMaxPDR
            );
            break;
        }
        if (result) {
          let appraised =
            +(result.DentCoverage || 0) +
            (result.OversizeCoverage || 0) +
            (result.Upcharge || 0);

          selectedPanel.appraised = Math.round(appraised * 100) / 100;
          selectedPanel.result = result;
        }
      }

      this.staticSubJobs = [];
      let isDamage = this.panels.find(
        (i) => i.damageCategory != null || (i.dentCount && i.dentCount > 0)
      );
      let isPanelParts = this.panels.find((i) => i.parts.length > 0);
      let isParts = this.parts.length > 0;
      let isMoldings = this.panels.find((i) => i.moldings.length > 0);
      let isConRepair = this.panels.find(
        (i) => i.flags.indexOf('CONVENTIONAL REPAIR') > -1
      );
      for (let subjob of this.masterData.subJobs) {
        if (subjob.isPermanent) {
          this.staticSubJobs.push(subjob);
        } else {
          if (
            isDamage &&
            (subjob.subJobType == 'randi' || subjob.subJobType == 'pdr')
          )
            this.staticSubJobs.push(subjob);
          if (
            (isParts || isPanelParts || isMoldings) &&
            subjob.subJobType == 'partinstall'
          )
            this.staticSubJobs.push(subjob);
          if (isConRepair && subjob.subJobType == 'paintshop')
            this.staticSubJobs.push(subjob);
        }
      }

      // this.panels[this.panelIndex] = selectedPanel;
      // let roofPanel = this.panels.find(i => i.panelId == 2);
      //
      // this.isLargeVehicle = false;
      // if (roofPanel) {
      //
      //     let isLargeVehicle = roofPanel.flags.find(i => i == "LARGE VEHICLE");
      //     if (isLargeVehicle) {
      //         this.isLargeVehicle = true;
      //     } else {
      //         this.isLargeVehicle = false;
      //     }
      // }
      // for (let panel of this.panels) {

      //     if (this.isLargeVehicle) {
      //         if (dentGridSelected.DentGridId = 600) {
      //             if (dentGridSelected.DentGridRules.LargeVehicleUpchargePercent) {
      //                 panel.appraised = panel.appraisedNoLarge + (panel.appraisedNoLarge * dentGridSelected.DentGridRules.LargeVehicleUpchargePercent) / 100;
      //             }

      //         }else{
      //             panel.appraised = panel.appraisedNoLarge;
      //         }

      //     } else {
      //         panel.appraised = panel.appraisedNoLarge;
      //     }
      // }

      this.calculation.calculateTotalValues(
        this.panels,
        this.panelBodyList,
        this.parts,
        this.general.totalSubletCharge,
        this.general.appraisedRI,
        this.general.flatratePDR,
        this.general.flatrateRI,
        this.general.partAmount,
        this.customerDiscount,
        this.customerTaxRegion,
        this.customerExempt
      );
      this.generateReport();
    } else if (sessionStorage.getItem('dentGrid') != 'undefined') {
      this.calculation.calculateTotalValues(
        this.panels,
        this.panelBodyList,
        this.parts,
        this.general.totalSubletCharge,
        this.general.appraisedRI,
        this.general.flatratePDR,
        this.general.flatrateRI,
        this.general.partAmount,
        this.customerDiscount,
        this.customerTaxRegion,
        this.customerExempt
      );
      this.generateReport();
    }
  }

  getOldVesionDentGrid(dentGridId, version) {
    dentGridId = dentGridId + version;
    let masterData = JSON.parse(localStorage.getItem('masterData'));
    if (masterData) {
      let dentGridList = masterData.DentGrids;
      for (let j = 0; j < dentGridList.length; j++) {
        if (dentGridList[j].DentGridId == dentGridId) {
          return dentGridList[j];
        }
      }
      return null;
    } else {
      return null;
    }
  }

  generateReport() {
    if (this.report) {
      this.report.pdrCharges = [];
      this.report.totalPDR = 0;
      this.report.partsRequire = [];
      this.report.partTotal = 0;
      this.report.externalParts = [];
      this.report.netSupliment = 0;
      this.report.subtotalBeforeDiscount = 0;
      this.report.discountAmount = 0;
      this.report.subtotalBeforeTax = 0;
      this.report.taxRate1 = 0;
      this.report.taxRate2 = 0;
      this.report.taxRate3 = 0;
      this.report.taxAmount1 = 0;
      this.report.taxAmount2 = 0;
      this.report.taxAmount3 = 0;
      this.report.subletCharges = [];
      this.report.total = 0;
      this.report.totalLessDeductible = 0;
      this.report.selectedParts = [];
    } else {
      this.report = {
        pdrCharges: [],
        totalPDR: null,
        netSupliment: null,
        partTotal: 0,
        externalParts: [],
        subtotalBeforeDiscount: null,
        discountAmount: null,
        subtotalBeforeTax: null,
        taxRate1: 0,
        taxRate2: 0,
        taxRate3: 0,
        taxAmount1: 0,
        taxAmount2: 0,
        taxAmount3: 0,
        total: 0,
        partsRequire: [],
        subletCharges: [],
        totalLessDeductible: 0,
        selectedParts: [],
      };
    }

    for (let panel of this.panels) {
      let amount = 0;
      const isConventionalRepairIndex = panel.flags.indexOf(
        'CONVENTIONAL REPAIR'
      );
      const isMaxPDRIndex = panel.flags.indexOf('MAX PDR');
      const isP2sIndex = panel.flags.indexOf('P2P');
      let isConventionalRepair = false;
      if (isConventionalRepairIndex > -1) isConventionalRepair = true;
      let isMaxPDR = false;
      if (isMaxPDRIndex > -1) isMaxPDR = true;
      let isP2s = false;
      if (isP2sIndex > -1) isP2s = true;
      if (panel.flatRate && panel.flatRate > 0) {
        amount = panel.flatRate;
        let bodyPanel = this.panelBodyList.find(
          (i) => i.PanelId == panel.panelId
        );
        if (bodyPanel) {
          let aLength = panel.appraisal.length;
          let appraisal = '';
          if (panel.appraisal[aLength - 1]) {
            appraisal = panel.appraisal[aLength - 1];
          }
          const charge = {
            panelName: bodyPanel.PanelName + ' ' + appraisal,
            amount: amount,
          };
          this.report.totalPDR = this.report.totalPDR + amount;
          this.report.pdrCharges.push(charge);
        }
      } else {
        // if (panel.appraised && panel.appraised > 0) {
        //     amount = panel.appraised;
        //     let bodyPanel = this.panelBodyList.find(i => i.PanelId == panel.panelId);
        //     if (bodyPanel) {
        //         let aLength = panel.appraisal.length;
        //         let appraisal = '';
        //         if (panel.appraisal[aLength - 1]) {
        //             appraisal = panel.appraisal[aLength - 1];
        //         }
        //         const charge = {
        //             panelName: bodyPanel.PanelName + ' ' + appraisal,
        //             amount: amount
        //         }
        //         this.report.totalPDR = this.report.totalPDR + amount;

        //         this.report.pdrCharges.push(charge);
        //     }
        // } else if (isMaxPDR || isConventionalRepair) {
        //     amount = panel.appraised;
        //     let bodyPanel = this.panelBodyList.find(i => i.PanelId == panel.panelId);
        //     if (bodyPanel) {
        //         let aLength = panel.appraisal.length;
        //         let appraisal = '';
        //         if (panel.appraisal[aLength - 1]) {
        //             appraisal = panel.appraisal[aLength - 1];
        //         }
        //         const charge = {
        //             panelName: bodyPanel.PanelName + ' ' + appraisal,
        //             amount: amount
        //         }
        //         this.report.totalPDR = this.report.totalPDR + amount;

        //         this.report.pdrCharges.push(charge);
        //     }
        // }
        let tempAppraisalStr = '';
        if (panel.appraisal) {
          if (panel.appraisal.length > 0) {
            tempAppraisalStr = panel.appraisal[0];
          }
        }
        if (tempAppraisalStr != 'N/D') {
          amount = panel.appraised;
          let bodyPanel = this.panelBodyList.find(
            (i) => i.PanelId == panel.panelId
          );
          if (bodyPanel) {
            let aLength = panel.appraisal.length;
            let appraisal = '';
            if (panel.appraisal[aLength - 1]) {
              appraisal = panel.appraisal[aLength - 1];
            }
            const charge = {
              panelName: bodyPanel.PanelName + ' ' + appraisal,
              amount: amount,
            };
            this.report.totalPDR = this.report.totalPDR + amount;

            this.report.pdrCharges.push(charge);
          }
        }
      }
      for (let molding of panel.moldings) {
        this.report.partsRequire.push(
          this.masterData.moldings.find((i) => i.MoldingId == molding)
            .MoldingName
        );
      }
      for (let part of panel.parts) {
        this.report.externalParts.push(part);
      }
    }
    for (let sublet of this.general.subletCharges) {
      if (sublet.amount > 0) {
        this.report.subletCharges.push(sublet);
      }
    }
    for (let part of this.parts) {
      this.report.externalParts.push(part);
    }

    this.report.subtotalBeforeDiscount =
      this.calculation.subtotalBeforeDiscount;
    this.report.discountAmount = this.calculation.discountAmount;

    this.report.subtotalBeforeTax = this.calculation.subtotalBeforeTax;
    this.report.taxRate1 = this.calculation.taxRate1;
    this.report.taxRate2 = this.calculation.taxRate2;
    this.report.taxRate3 = this.calculation.taxRate3;
    this.report.taxAmount1 = this.calculation.taxAmount1;
    this.report.taxAmount2 = this.calculation.taxAmount2;
    this.report.taxAmount3 = this.calculation.taxAmount3;
    this.report.partTotal = this.calculation.partTotal;

    this.report.total = this.calculation.invoiceTotal;
    this.report.totalLessDeductible =
      this.report.total - this.general.deductible;
    if (this.report.totalLessDeductible < 0) {
      this.report.totalLessDeductible = 0;
    }
  }
}

export interface reportUI {
  pdrCharges: Array<any>;
  totalPDR: number;
  netSupliment: number;
  subtotalBeforeDiscount: number;
  discountAmount: number;
  subtotalBeforeTax: number;
  partTotal: number;
  externalParts: Array<any>;
  taxRate1: number;
  taxRate2: number;
  taxRate3?: number;
  taxAmount1: number;
  taxAmount2: number;
  taxAmount3?: number;
  total: number;
  partsRequire: Array<string>;
  subletCharges: Array<sublet>;
  totalLessDeductible: number;
  selectedParts: Array<SelectablePartItem>;
}

export interface panelUI {
  panelId: number;
  appraisal: Array<string>;
  appraisalDetails?: Array<any>;
  damageCategory: number;
  dentSizeCategoryId: number;
  oversize: number;
  flags: Array<string>;
  moldings: Array<number>;
  panelName: string;
  partPrize: number;
  appraisedNoLarge: number;
  appraised: number;
  flatRate: number;
  dentCount: number;
  parts: Array<partIn>;
  result?: any;
}

export interface generalUI {
  removeNis: Array<number>;
  customRI: number;
  appraisedRI: number;
  subletCharges: Array<sublet>;
  totalSubletCharge: number;
  flatratePDR: number;
  flatrateRI: number;
  deductible: number;
  rNiArray: Array<any>;
  partAmount: number;
}

export interface partsUI {
  partName: string;
  partPrize: number;
}
export interface removeInstallCat {
  RemoveInstallCategoryId: number;
  Name: string;
  Description: string;
  Scope: string;
  PanelId: number;
}
export interface sublet {
  name: string;
  amount: number;
}
export interface partIn {
  name: string;
  price: number;
  status?: string;
}

export interface SelectablePartItem {
  id: number;
  name: number;
}
