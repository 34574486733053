import { Panel } from './vehicle';
import { peopleIn } from './people';
import { Router } from '@angular/router';
import { SelectablePartItem } from './estimate';

export class Job {
  jobId: number;
  isDifficultCustomer: boolean;
  isSiu: boolean;
  isRentCar: boolean;
  rentalAgreementNumber: string;
  notificationFrequency: number;
  lastTechEntryId: number;
  startDate: Date;
  endDate: Date;
  updatedAt: Date;
  vehicle: VehicleIn;
  customer: CustomerIn;
  workOrder: WorkOrderIn;
  estimate: EstimateIn;
  people: Array<peopleIn>;
  isDetailLoaded: boolean;
  locationId: number;
  isComebackRequired: boolean;
  isSkipBackdating: boolean;

  constructor() {
    this.jobId = 0;
    this.isDifficultCustomer = false;
    this.isComebackRequired = false;
    this.isSiu = false;
    this.isRentCar = false;
    this.isSkipBackdating = false;
    (this.rentalAgreementNumber = null),
      (this.notificationFrequency = 1),
      (this.lastTechEntryId = 0);
    this.startDate = new Date();
    this.endDate = new Date();
    this.updatedAt = new Date();
    (this.locationId = null),
      (this.vehicle = {
        vin: '',
        year: null,
        makeId: null,
        makeName: null,
        modelId: null,
        modelName: null,
        trimLevel: null,
        bodyType: null,
        bodySize: null,
        isUsed: null,
        stockNo: null,
        plate: null,
        odometer: null,
        color: null,
        ownerName: null,
        ownerEmail: null,
        ownerPhone: null,
        ownerAddress: null,
        panelList: [],
        isPdrOnly: null,
        isBodyShop: null,
      });
    this.customer = {
      userId: null,
      discount: null,
      phoneNo: null,
      email: null,
      taxRegion: null,
      extemptions: {},
    };
    this.workOrder = {
      projectId: null,
      insurerId: null,
      dentGridId: null,
      poNumber: null,
      claimNumber: null,
      startDate: new Date(),
      endDate: new Date(),
      invoiceNotes: null,
      privateNotes: null,
      timeline: {},
      subJobs: [],
    };
    this.estimate = {
      report: {},
      panels: [],
      general: {},
      parts: [],
      vehicleModelId: null,
      vehicleYear: null,
      selectedParts:[],
      unlistedParts:[],
    };
    this.people = [];
    this.isDetailLoaded = false;
  }

  public static setSessionStorageValues(
    jobId: number,
    jobName: string,
    vinNo: string,
    customerName: string
  ): void {
    sessionStorage.setItem('isNewJob', 'false');
    sessionStorage.setItem('jobId', jobId.toString());
    sessionStorage.setItem('jobName', jobName);
    sessionStorage.setItem('vinNo', vinNo);
    sessionStorage.setItem('customerName', customerName);
  }

  public static navigateToJobDetails(
    jobId: number,
    jobName: string,
    vinNo: string,
    _router: Router
  ): void {
    _router.navigate(['/jobs/job-list/job-manage/job-details'], {
      replaceUrl: true,
      queryParams: {
        isNewJob: 'false',
        jobId: jobId,
        jobName: jobName,
        vinNo: vinNo,
      },
    });
  }
}

export interface VehicleIn {
  vin: string;
  year: number;
  makeId: number;
  makeName: string;
  modelId: number;
  modelName: string;
  trimLevel: string;
  bodyType: string;
  bodySize: string;
  isUsed: boolean;
  stockNo: string;
  plate: string;
  odometer: number;
  color: string;
  ownerName: string;
  ownerEmail: string;
  ownerPhone: string;
  ownerAddress: string;
  panelList: Array<Panel>;
  isPdrOnly: boolean;
  isBodyShop: boolean;
}
export interface CustomerIn {
  userId: string;
  name?: string;
  discount: number;
  phoneNo: number;
  email: string;
  taxRegion: string;
  extemptions: Object;
}
export interface WorkOrderIn {
  projectId: number;
  projectName?: string;
  insurerId: number;
  insurerName?: string;
  dentGridId: number;
  dentGridName?: string;
  poNumber: string;
  claimNumber: string;
  startDate: Date;
  endDate: Date;
  invoiceNotes: string;
  privateNotes: string;
  timeline: any;
  subJobs: Array<any>;
}
export interface EstimateIn {
  report: any;
  panels: Array<any>;
  general: any;
  parts: Array<any>;
  vehicleModelId: number;
  vehicleYear: number;
  isLargeVehicle?: boolean;
  selectedParts:Array<any>;
  unlistedParts:Array<SelectablePartItem>;
}
export interface checkInFormIn {
  name: string;
  vehicle: string;
  claimNo: number;
  date: Date;
  signature: Array<any>;
  damage: Array<any>;
  notes: string;
}

export interface JobsFilterData {
  _id: string | number;
  name: string;
  accountingID?: string;
}

export interface TableHeader {
  name: string;
  sortBy: number;
  filterData?: Array<JobsFilterData>;
  type: number;
  key: string;
  subFields?: Array<any>;
  selectedFilters?: Array<JobsFilterData>;
}

export interface TableData {
  headers: Array<TableHeader>;
  data: Array<any>;
  selectedSort: number;
  sortDirection: string;
  disabledFieldKey?: string;
}
