export class User {
    id: string;
    name: string;
    password: string;
    isGSTAvailable:boolean;
    isHSTAvailable?:boolean;
    status: number;
    userLevel: number;
    userType:number;
    isVirtual: boolean;
    deviceType: number;
    profileImageUdated: number;
    braintreeCustomerCreated: boolean;
    email: string;
    userProfileId: string;
    privacyHideLocation: boolean;
    privacyHideMap: boolean;
    privacyActivityOption: number;
    firstName: string;
    lastName: string;
    isProjectTabEnable?:boolean;
    isCreateJobEnable?:boolean;
    isReadOnly?:boolean;
    address1?:any;
    phoneNo?:any;
    
}
