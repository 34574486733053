import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from 'src/app/interceptors/error';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomHeader } from './interceptors/custom-header';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { NgChartsModule } from 'ng2-charts';
import { RouterService } from './_services/data/router.service';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { IonicSelectableComponent } from 'ionic-selectable';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    IonicSelectableComponent,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MaterialModule,
    NgChartsModule,
  ],
  providers: [
    Camera,
    BarcodeScanner,
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    RouterService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHeader, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
