import { Component } from '@angular/core';
import { RouterService } from './_services/data/router.service';
import { register } from 'swiper/element/bundle';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private _routerData:RouterService) {}
}
