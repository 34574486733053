export class MasterData {
  panels: Array<any>;
  panelDetails: Array<any>;
  moldings: Array<any>;
  makes: Array<any>;
  models: Array<any>;
  bodyTypes: Array<any>;
  bodySizes: Array<any>;
  regions: Array<any>;
  roles: Array<any>;
  jobStates: Array<any>;
  dentDamageCategories: Array<any>;
  dentSizeCategories: Array<any>;
  removeInstallCategories: Array<any>;
  dentGrids: Array<any>;
  payoutRates: any;
  gstRate: number;
  contentTypes: Array<any>;
  subJobs: Array<subJobList>;
  flags: Array<flagList>;
  panelFlags: Array<panelFlag>;
  maxPanelValue: number;
  parts: Array<any>;

  constructor() {
    let masterData = JSON.parse(localStorage.getItem('masterData'));
    this.panels = masterData.Panels;
    this.panelDetails = masterData.PanelDetails;
    this.moldings = masterData.Moldings;
    this.makes = masterData.Makes;
    this.models = masterData.Models;
    this.bodyTypes = masterData.BodyTypes;
    this.bodySizes = masterData.BodySizes;
    this.regions = masterData.Regions;
    this.roles = masterData.Roles;
    this.jobStates = masterData.JobStages;
    this.dentGrids = [];
    this.loadDentGrids(masterData);
    this.dentDamageCategories = masterData.DentDamageCategories;
    this.dentSizeCategories = masterData.DentSizeCategories;
    this.removeInstallCategories = masterData.RemoveInstallCategories;
    this.subJobs = masterData.subJobs;
    this.flags = masterData.flags;
    this.panelFlags = masterData.panelFlags;
    this.payoutRates = masterData.PayoutRates;
    this.maxPanelValue = masterData.maxPanelValue;
    this.parts = masterData.Parts;
  }
  loadDentGrids(masterData) {
    let dentGridList = masterData.DentGrids;
    for (let j = 0; j < dentGridList.length; j++) {
      if (!dentGridList[j].isTemp) {
        this.dentGrids.push(dentGridList[j]);
      }
    }
  }
}

export interface subJobList {
  subJobType: string;
  name: string;
  icon: string;
  isPermanent: boolean;
  isDefault: boolean;
  jobStage: string;
  allowDropDowns?: boolean;
}
export interface flagList {
  flags: Array<number>;
  modelId: number;
  panelId: number;
  year: number;
}

export interface panelFlag {
  id: number;
  name: string;
}
