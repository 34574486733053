import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guard/auth.guard';
import { RoleGuard } from 'src/app/guard/role.guard';
import { PublicGuard } from './guard/public.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
    data: {
      preload: true,
    },
  },
  {
    path: 'login',
    canActivate: [PublicGuard],
    loadChildren: () =>
      import('src/app/public/login/login.module').then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/home/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: 'overall-dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/home/overall-dashboard/overall-dashboard.module').then(
        (m) => m.OverallDashboardPageModule
      ),
  },
  {
    path: 'jobs',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/home/jobs/jobs.module').then((m) => m.JobsPageModule),
  },
  {
    path: 'jobs/job-list/job-manage',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/home/job-manage/job-manage.module').then(
        (m) => m.JobManagePageModule
      ),
  },
  {
    path: 'jobs/ecd-job-details',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/home/jobs/ecd-job-details/ecd-job-details.module').then(
        (m) => m.EcdJobDetailsModule
      ),
  },
  {
    path: 'part-list',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/home/part-list/part-list.module').then(
        (m) => m.PartListModule
      ),
  },
  {
    path: 'workflow',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/home/workflow/workflow.module').then(
        (m) => m.WorkflowModule
      ),
  },
  {
    path: 'projects',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/home/projects/projects.module').then(
        (m) => m.ProjectsPageModule
      ),
  },
  {
    path: 'myaccount',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/home/myaccount/myaccount.module').then(
        (m) => m.MyaccountPageModule
      ),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/app/home/myprofile/myprofile.module').then(
        (m) => m.MyprofilePageModule
      ),
  },
  {
    path: 'accounts',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('src/app/home/accounts/accounts.module').then(
        (m) => m.AccountsPageModule
      ),
  },
  {
    path: 'accounts/account-manage',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('src/app/home/account-manage/account-manage.module').then(
        (m) => m.AccountManagePageModule
      ),
  },
  {
    path: 'check-in-form',
    loadChildren: () =>
      import('src/app/home/job-manage/check-in-form/check-in-form.module').then(
        (m) => m.CheckInFormPageModule
      ),
  },
  {
    path: 'status',
    loadChildren: () =>
      import('src/app/public/status/status.module').then(
        (m) => m.StatusPageModule
      ),
  },
  {
    path: 'automation',
    loadChildren: () =>
      import('src/app/home/automation/automation.module').then(
        (m) => m.AutomationModule
      ),
  },
  {
    path: 'overhead-display',
    loadChildren: () =>
      import('src/app/home/overhead-display/overhead-display.module').then(
        (m) => m.OverheadDisplayPageModule
      ),
  },
  {
    path: 'on-going-job-list',
    loadChildren: () =>
      import('src/app/home/on-going-job-list/on-going-job-list.module').then(
        (m) => m.OnGoingJobListPageModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('src/app/home/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
