import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpErrorResponse } from '@angular/common/http';
import { MasterData } from 'src/app/_models';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  constructor(private http: HttpClient) {}

  getMasterData() {
    return this.http.get('./../../assets/data/staticRef.json').subscribe(
      (data) => {
        // data = data as string [];	 // FILL THE ARRAY WITH DATA.
        localStorage.setItem('masterData', JSON.stringify(data));
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let userType = currentUser.userType;
        if (userType) {
          let masterData = new MasterData();
          let userTypeObj = masterData.roles.find((i) => i.RoleId == userType);
          if (userTypeObj) {
            localStorage.setItem('roleName', userTypeObj.RoleName);
          }
        }

        return data;
      },
      (err: HttpErrorResponse) => {}
    );
  }
}
